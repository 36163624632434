<template>
   <el-col :span="8" :class="couponItem.status===0||couponItem.status===2?'coupon used':'coupon'">
    <el-col :span="16" class="coupon-left">
      <div class="coupondes">
        <!-- X{{couponItem.num-couponItem.hasUsedNum}}张 -->
        <p><span>名称:</span><span>{{ couponItem.couponName }}</span></p>
        <p><span>编号:</span><span>{{ couponItem.id }}</span></p>
        <p><span>说明:</span>
          <span class="desBox" :title="couponItem.couponDescription">{{ couponItem.couponDescription }}</span>
        </p>
      </div>
      <div class="dateName">使用时间：</div>
      <div class="date" v-if=" couponItem.validEndTimeFlag==-1 ">{{couponItem.validStartTime.substring(0,10).replace(/-/g,'.')}} — {{couponItem.validEndTime.substring(0,10).replace(/-/g,'.')}}</div>
      <div class="date" v-else>{{couponItem.validStartTime.substring(0,10).replace(/-/g,'.')}} — {{couponItem.failureTime.substring(0,10).replace(/-/g,'.')}}</div>
    </el-col>
    <el-col :span="8" class="text-center coupon-right">
       <div class="numWraper">
         <span class="num" v-if="couponItem.promotionMethod==0">￥{{ couponItem.promotionValue }}</span>
         <span class="num" v-if="couponItem.promotionMethod==1">{{ couponItem.promotionValue }}折</span>
      </div>
        <!-- 无门槛 --><div class="numMsg"  v-if="couponItem.useCondition==0">全场商品可用</div>
        <!-- 满减 --><div class="numMsg"  v-if="couponItem.useCondition==1">满{{ couponItem.useAmountCondition }}元可用</div>
        <!-- 数量 --><div class="numMsg"  v-if="couponItem.useCondition==2">
            <!-- 满{{ couponItem.useNumCondition }}个品种可用 -->
                      <span v-if="couponItem.couponType==4">满{{ couponItem.useNumCondition }}个未购商品可用</span>
                      <span v-else>满{{ couponItem.useNumCondition }}个品种可用</span>  
                  </div>
    </el-col>
  </el-col>
</template>

<script>
export default {
  name: "CouponItem",
  data() {
    return {
     
    };
  },
  props:{
          couponItem:{
              type:Object
            }
           
    },
  methods: {},
  created() {
  },
  components: {},
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.coupon {
  margin-bottom: 20px;
  margin-right: 14px;
  width: 294px;
  height: 146px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  .coupon-left {
    width:190x;
    height: 146px;
    position: relative;
    background: #f6f6f6;
    font-size: 12px;
    color: #F43C38 99;
    border-radius: 5px;
    .coupondes {
      padding:13px 6px;
      p{
        font-size: 13px;
        width: 100%;
        margin-bottom:5px;
        span:nth-child(1) {
          color: #999;
        }
        span:nth-child(2) {
          color: #666;
        }
        .desBox{
          overflow:hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp:2;
          -webkit-box-orient: vertical;
          width: 83%;
          float: right;
          cursor: pointer;
        }
      }
    }

    .dateName{
      color: #999;
      text-indent:4px;
      position: absolute;
      bottom:27px;
    }
    .date {
      color: #333;
      font-size: 12px;
      position: absolute;
      bottom: 10px;
      text-align: left;
      text-indent:18px;
    }
  }
  .coupon-right {
    background-color: #F43C38 ;
    flex: 1;
    height: 146px;
    border-radius: 5px;
    .numMsg {
      color: #fff;
      font-size: 12px;
    }
    .numWraper {
      color: #fff;
      font-size:16px;
      margin-top:46px;
      font-weight: bold;
      .num {
        color: #fff;
        font-weight: 600;
        font-size:20px;
      }
    }
  }
}

.coupon:after,
.coupon:before {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  z-index: 2;
  left: 184px;
  background: #fff;
  border-radius: 10px;
}
.coupon:after {
  bottom: -10px;
}
.coupon:before {
  top: -10px;
}

.coupon-left:after {
  content: "";
  position: absolute;
  top: 3px;
  display: block;
  width: 10px;
  height: 100%;
  right: -3px;
  z-index: 1;
  background-position: 0 0px;
  background-color: transparent;
  background-size: 10px 10px;
  background-image: radial-gradient(
    circle at right,
    #F43C38  6px,
    transparent 0
  );
}

.coupon.used {
  .coupon-left {
    .dataMsg {
      color: #999;
      font-size: 16px;
      position: absolute;
      bottom: 30px;
      text-align: center;
      width: 100%;
    }
  }

  .coupon-left:after {
    background-image: radial-gradient(circle at right, #ccc 6px, transparent 0);
  }
  .coupon-right {
    background: #ccc;
    border-left: none;
    .numMsg {
      color: #999;
    }
  }
}
</style>
